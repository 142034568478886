* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1b3f;
  overflow-x: hidden;
}
.cancel-icon:hover {
  color: #c64415;
}

@media (max-width: 1023px) {
  body {
    position: relative;
    padding: 0 5vw 10vw;
  }
}
